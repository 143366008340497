<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label>Projekty</ion-label>
				</ion-title>
				
				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>
		<ion-content>

			<div class="container px-1">
				<h1 class="mb-3 mt-5">Projekty</h1>
				<ion-searchbar class="mb-5" v-model="searchQuery" @ionChange="filterProjects" show-cancel-button="never" placeholder="Vyhľadať projekty"></ion-searchbar>
			</div>
			<w-loadable :items="projects" skelet="spinner">
				<ion-row v-if="projects" class="container mb-3">
					<ion-col size="12" size-sm="6" size-lg="4" v-for="project in filteredProjects" @click="$router.push(`/project/${project.id}`)" :key="project.id">
						<ion-card>
							<img  class="thumbnail" :src="project.image.path">
							<div class="description d-flex flex-direction-column ion-justify-content-between">
								<h3>{{ project.name }}</h3>
								<div class="autor d-flex ion-align-items-center">
									<img :src="project.author.avatar ? project.author.avatar.path : require('../_components/img/use_img.png')" alt="">
									<p>{{ project.author.username }}</p>
								</div>
							</div>
						</ion-card>
					</ion-col>
				</ion-row>
			</w-loadable>
		</ion-content>
	</ion-page>
</template>

<script>
import { personCircleOutline, logInOutline } from 'ionicons/icons'
import wAxios from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'

const api = {
	getProjects: () => wAxios.get_data('https://gamisfera.hemisfera.sk/cms/api/projects'),
}

export default {
	data() {
		return {
			projects: null,
			filteredProjects: [],
			searchQuery: "",
			personCircleOutline,
			logInOutline
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},

	created() {
		this._loadProjects()
	},

	methods: {

		filterProjects() {
			console.log('value changed')
			if (this.searchQuery.trim() == '') this.filteredProjects = this.projects
			else {
				this.filteredProjects = this.projects.filter(project => {
					console.log(project.name, this.searchQuery)
					return project.name.toLowerCase().includes(this.searchQuery.toLowerCase())
				})
			}
		},

		async _loadProjects() {
			try {
				const projects = await api.getProjects()
				this.projects = projects
				this.filteredProjects = projects
			}
			catch(error) {
				console.error(error)
			}
		}
	}
}
</script>

<style lang="sass" scoped>
ion-col
	--ion-grid-column-padding: 15px

ion-card
	height: 450px
	box-shadow: none
	border: 1px solid rgba(0, 0, 0, .1)
	transition: .2s
	padding: 0

	&:hover
		box-shadow: 0px 2px 12px 0px lightgray

	.thumbnail
		width: 100%
		height: 230px
		object-fit: cover

	.description
		width: 100%
		height: 220px
		padding: 50px 35px

		.autor
			p
				font-weight: 400
				margin: 0

			img
				height: 25px
				margin-right: 10px
		
@media only screen and (max-width: 500px)
	.container
		padding: 0

	h1
		margin: 50px auto 30px
		width: 90%

	ion-searchbar
		margin: 0 auto 50px
		width: 90%

@media only screen and (max-width: 1199px)
	.container
		max-width: 1000px !important

@media only screen and (max-width: 992px)
	.container
		max-width: 700px !important

</style>